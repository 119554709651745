
import EstadoTag from "@/components/widgets/EstadoTag.vue";
import comarcas from "@/core/data/comarcas";
import { nombreIdioma } from "@/core/data/idiomas";
import lugaresEspaciosColectivos from "@/core/data/lugaresEspaciosColectivos";
import lugaresPrestacion from "@/core/data/lugaresPrestacion";
import tratamientos from "@/core/data/tratamientos";
import vehiculos from "@/core/data/vehiculos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FormularioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { AuthModule, ServiciosProgramadosModule } from "@/store/modules";
import { ArrowDown } from "@element-plus/icons-vue";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Formulario {
  createdOn: Date;
  tratamiento: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
  nombreServicio: string | undefined;
  lugarPrestacion: string | undefined;
  lugarEspacioColectivo?: string | null;
  otrosEspacioColectivo?: string | null;
  direccion: string | undefined;
  comarcaId: string | undefined;
}

export default defineComponent({
  name: "listados-servicio-detalle",
  components: {
    ErrorMessage,
    Page,
    ArrowDown,
    EstadoTag,
  },

  methods: {
    formatDate(fecha) {
      return moment(fecha).format("DD/MM/YYYY hh:mm");
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const token = route.params.id;

    const store = useStore();
    const serviciosProgramadosModule = getModule(
      ServiciosProgramadosModule,
      store
    );
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const cargaDetalle = ref(false);
    const crearServicioProgramado = ref(false);

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await serviciosProgramadosModule.fetchServiciosColectivosSolicitadosDetalle(
          { comarcaId: comarcaId, id: token as string }
        );
      } catch (err) {
        Swal.fire({
          text: t(
            "formularios.solicitudServicioProgramadoDetalle.cargar.error"
          ),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    const datosSolicitudServicio = computed(
      () => serviciosProgramadosModule.formulario
    );
    const dialogVisible = ref(false);
    const dialogLoading = ref(false);
    const dialogSelected = ref<string>();

    const error = computed(() => serviciosProgramadosModule.errors);
    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });
    const servicioColectivoUrl = ref("");

    watch(datosSolicitudServicio, () => {
      if (!datosSolicitudServicio.value) return;
      const menor =
        datosSolicitudServicio.value.menor ??
        datosSolicitudServicio.value.datosSolicitud?.menores[0];
      const comarcaId = datosSolicitudServicio.value.comarcaId;

      servicioColectivoUrl.value = datosSolicitudServicio.value
        .servicioProgramado?.id
        ? "/listados/servicios-programados/" +
          datosSolicitudServicio.value.servicioProgramado?.id
        : "";

      estado.value = datosSolicitudServicio.value.estado;
      estadoOrginal.value = datosSolicitudServicio.value.estado;
      resetForm({
        values: {
          tratamiento: menor.tratamiento,
          nombre: menor.nombre,
          apellidos: menor.apellidos,
          createdOn: datosSolicitudServicio.value.createdOn,
          fechaNacimiento: menor.fechaNacimiento,
          lugarPrestacion:
            datosSolicitudServicio.value.servicioProgramado?.servicio
              .lugarPrestacion,
          lugarEspacioColectivo:
            datosSolicitudServicio.value.servicioProgramado?.servicio
              .lugarEspacioColectivo,
          otrosEspacioColectivo:
            datosSolicitudServicio.value.servicioProgramado?.servicio
              .otrosEspacioColectivo,
          nombreServicio:
            datosSolicitudServicio.value.servicioProgramado?.nombre,
          direccion:
            datosSolicitudServicio.value.servicioProgramado?.servicio.direccion,
          comarcaId: comarcaId,
        },
      });

      setCurrentPageBreadcrumbs(menor.nombre + " " + menor.apellidos ?? "", [
        t("menu.servicios"),
        {
          title: t("menu.serviciosSolicitados"),
          path: "/solicitudes/servicios",
        },
      ]);
    });

    const schema = Yup.object({
      tratamiento: Yup.string().required(),
      nombre: Yup.string().required(),
      apellidos: Yup.string().required(),
      createdOn: Yup.date().required(),
      fechaNacimiento: Yup.date().required(),
      lugarPrestacion: Yup.string().required(),
      lugarEspacioColectivo: Yup.string().nullable(),
      otrosEspacioColectivo: Yup.string().nullable(),
      direccion: Yup.string().required(),
      nombreServicio: Yup.string().required(),
      comarcaId: Yup.string().required(),
    });

    const { resetForm, handleSubmit, errors } = useForm<Formulario>({
      validationSchema: schema,
    });

    const estado = ref<string>("");
    const estadoOrginal = ref<string>("");
    const { value: createdOn } = useField("createdOn");
    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: fechaNacimiento } = useField("fechaNacimiento");
    const { value: lugarPrestacion } = useField("lugarPrestacion");
    const { value: lugarEspacioColectivo } = useField("lugarEspacioColectivo");
    const { value: otrosEspacioColectivo } = useField("otrosEspacioColectivo");
    const { value: direccion } = useField("direccion");
    const { value: nombreServicio } = useField("nombreServicio");
    const { value: comarcaIdV } = useField("comarcaId");

    const editable = computed(() => {
      return !(
        datosSolicitudServicio.value?.estado === FormularioEstado.incorporado &&
        !datosSolicitudServicio.value?.menor
      );
    });
    const familia = computed(() => {
      return (
        datosSolicitudServicio.value?.familia ||
        datosSolicitudServicio.value?.peticionFormulario?.datosSolicitud
      );
    });

    const familiaDNI = computed(
      () =>
        datosSolicitudServicio.value?.familia?.dni ||
        datosSolicitudServicio.value?.peticionFormulario?.datosSolicitud?.dni ||
        datosSolicitudServicio.value?.dni
    );

    const familiaTelefono = computed(
      () =>
        datosSolicitudServicio.value?.familia?.telefono ||
        datosSolicitudServicio.value?.peticionFormulario?.datosSolicitud
          ?.telefono ||
        datosSolicitudServicio.value?.datosSolicitud.telefono
    );

    const familiaTratamiento = computed(() => {
      const result = tratamientos.find(
        (tr) => tr.id === familia.value?.tratamiento
      );

      return result ?? tratamientos[3];
    });

    const familiaAprobada = computed(
      () => !!datosSolicitudServicio.value?.familia
    );
    const solicitudFamiliaId = computed(
      () => datosSolicitudServicio.value?.peticionFormulario?.id
    );

    return {
      openServicioColectivoPicker: async () => {
        dialogVisible.value = true;
        dialogLoading.value = true;
        try {
          await serviciosProgramadosModule.fetchListado(
            comarcaIdV.value as string
          );
        } catch (err) {
          Swal.fire({
            text: t("formularios.serviciosProgramadosListado.cargar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          dialogLoading.value = false;
        }
      },
      closeServicioColectivoPicker: async () => {
        dialogVisible.value = false;
        dialogSelected.value = undefined;
      },
      asignarServicioColectivo: () => {
        const comarcaId = comarcaIdV.value as string;
        const idPeticionFormulario = token as string;
        const idServicioColectivo = dialogSelected.value as string;

        if (!comarcaId || !idPeticionFormulario || !idServicioColectivo) return;

        return serviciosProgramadosModule
          .reasignarServicioColectivo({
            comarcaId,
            idPeticionFormulario,
            idServicioColectivo,
          })
          .then(() => {
            dialogVisible.value = false;
            return Swal.fire({
              text: t("dialogs.reasignarAServicioColectivo.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .then(() => {
            router.replace(
              "/listados/servicios-programados/" + idServicioColectivo
            );
          })
          .catch(() => {
            Swal.fire({
              text: t("dialogs.reasignarAServicioColectivo.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
      guardar: handleSubmit(async (values) => {
        const comarcaId = comarcaIdV.value as string;
        const idPeticionFormulario = token as string;
        const idServicioColectivo =
          datosSolicitudServicio.value?.servicioProgramado?.id;

        if (!comarcaId || !idPeticionFormulario) return;

        cargaDetalle.value = true;
        return serviciosProgramadosModule
          .updateSolicitud({
            comarcaId,
            id: idPeticionFormulario,
            solicitud: {
              tratamiento: values.tratamiento,
              nombre: values.nombre,
              apellidos: values.apellidos,
              fechaNacimiento: values.fechaNacimiento,
            },
          })
          .then(() => {
            if (estadoOrginal.value === estado.value) return;

            if (estado.value === FormularioEstado.pendiente) {
              return serviciosProgramadosModule.pendiente({
                comarcaId: comarcaId,
                id: idServicioColectivo as string,
                peticionFormularioId: idPeticionFormulario,
              });
            } else if (estado.value === FormularioEstado.rechazado) {
              return serviciosProgramadosModule.rechazarSolicitud({
                comarcaId: comarcaId,
                id: idServicioColectivo as string,
                peticionFormularioId: idPeticionFormulario,
              });
            } else if (estado.value === FormularioEstado.realizadorPorError) {
              return serviciosProgramadosModule.realizadoPorErrorSolicitud({
                comarcaId: comarcaId,
                id: idServicioColectivo as string,
                peticionFormularioId: idPeticionFormulario,
              });
            } else if (estado.value === FormularioEstado.incorporado) {
              return serviciosProgramadosModule.aprobarSolicitud({
                comarcaId: comarcaId,
                id: idServicioColectivo as string,
                peticionFormularioId: idPeticionFormulario,
              });
            } else if (estado.value === FormularioEstado.listaDeEspera) {
              return serviciosProgramadosModule.listaDeEspera({
                comarcaId: comarcaId,
                id: idServicioColectivo as string,
                peticionFormularioId: idPeticionFormulario,
              });
            }
          })
          .then(() => {
            Swal.fire({
              text: t(
                "formularios.solicitudServicioProgramadoDetalle.editar.exito"
              ),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.replace("/solicitudes/servicios");
            });
          })
          .catch(() => {
            Swal.fire({
              text: t(
                "formularios.solicitudServicioProgramadoDetalle.editar.error"
              ),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .finally(() => {
            cargaDetalle.value = false;
          });
      }),
      estados: [
        {
          value: FormularioEstado.incorporado,
          label: t("estados.INCORPORADO"),
        },
        {
          value: FormularioEstado.pendiente,
          label: t("estados.PENDIENTE"),
        },
        {
          value: FormularioEstado.rechazado,
          label: t("estados.RECHAZADO"),
        },
        {
          value: FormularioEstado.realizadorPorError,
          label: t("estados.REALIZADO_POR_ERRROR"),
        },
        {
          value: FormularioEstado.listaDeEspera,
          label: t("estados.LISTA_DE_ESPERA"),
        },
      ],
      dialogVisible,
      dialogLoading,
      dialogSelected,
      dialogData: computed(() =>
        serviciosProgramadosModule.servicios.filter((servicio) => {
          return (
            servicio.inscripcionAbierta &&
            new Date(servicio.servicio.finPrevisto) > new Date()
          );
        })
      ),
      editable,
      familia,
      familiaAprobada,
      solicitudFamiliaId,
      familiaTratamiento,
      familiaTelefono,
      familiaDNI,
      nombreIdioma,
      vehiculos,
      errors,
      cargaDetalle,
      crearServicioProgramado,
      tratamientos,
      tratamiento,
      nombre,
      apellidos,
      createdOn,
      fechaNacimiento,
      lugarPrestacion,
      lugarEspacioColectivo,
      otrosEspacioColectivo,
      nombreEspacioColectivo: computed(() => {
        if (lugarPrestacion.value !== "ESPACIO") {
          return "";
        }

        let etiqueta = lugarEspacioColectivo.value;
        if (etiqueta === "OTROS") {
          etiqueta = otrosEspacioColectivo.value;
        }

        return (
          ": " +
          lugaresEspaciosColectivos.find((e) => e.id === etiqueta)?.nombre
        );
      }),
      direccion,
      nombreServicio,
      comarcaId: comarcaIdV,
      comarcas,
      lugaresPrestacion,
      lugaresEspaciosColectivos,
      estado,
      estadoOrginal,
      FormularioEstado,
      servicioColectivoUrl,
    };
  },
});
