
import {
  FamiliaEstado,
  FormularioEstado,
  ProfesionalEstado,
  ServicioEstado,
} from "@/enums";
import { computed, defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    estado: String,
  },
  setup(props) {
    return {
      classes: computed(() => {
        switch (props.estado) {
          case FormularioEstado.pendiente:
            return "badge badge-primary";
          case ProfesionalEstado.pendienteDoc:
          case FamiliaEstado.pendienteDoc:
          case FormularioEstado.listaDeEspera:
            return "badge badge-warning";
          case ProfesionalEstado.apto:
          case FamiliaEstado.apto:
          case ServicioEstado.asignado:
          case ServicioEstado.aprobado:
          case FormularioEstado.incorporado:
            return "badge badge-success";
          case ProfesionalEstado.suspendido:
          case FamiliaEstado.suspendido:
          case FormularioEstado.noApto:
          case FormularioEstado.rechazado:
          case FormularioEstado.realizadorPorError:
            return "badge badge-danger";
          default:
            return "d-none";
        }
      }),
    };
  },
});
